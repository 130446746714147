// -------------------------------------------------------------
//   Drupal
//   Defines Drupal styles
// -------------------------------------------------------------
body.admin-menu.adminimal-menu {
	.main-header {
		top: 29px;

		@include media-breakpoint-down(lg) {
			top: 47px !important;
		}
	}

	.slicknav_menu {
		position: fixed;
		z-index: 510;
		top: 0;
		left: 0;
		width: 100%;
	}
}

// Tabs
ul.tabs.primary {
	position: fixed;
	z-index: 300;
	right: 0;
	bottom: 0;
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		float: left;
		margin: 0;
		padding: 0;
		& + li {
			margin-left: 1px;
		}
		a {
			display: block;
			border: 0 !important;
			border-radius: 0;
			padding: 10px 15px;
			background-color: map-get($theme-colors, primary);
			color: #fff !important;
			text-decoration: none;
			@include transition(background-color 0.2s);

			&:hover, &.active {
				background-color: darken(map-get($theme-colors, primary), 15%);
			}
		}
	}
}

// Messages
.messages {
	margin-bottom: 60px;
	padding: 20px 30px;
	border: 1px solid transparent;
	border-radius: 5px;
	color: #fff;
	font-size: 1.15rem;

	/* These are technically the same, but use both */
	overflow-wrap: break-word;
	word-wrap: break-word;

	-ms-word-break: break-all;
	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: break-all;
	/* Instead use this non-standard one: */
	word-break: break-word;

	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;

	em {
		opacity: .75;
	}
	&.status {
		border-color: map-get($theme-colors, success);
		background-color: map-get($theme-colors, success);
		color: map-get($theme-colors, dark);
		a {
			color: transparentize(map-get($theme-colors, dark), .3);
		}
	}
	&.error {
		border-color: map-get($theme-colors, danger);
		background-color: map-get($theme-colors, danger);
		color: map-get($theme-colors, dark);
		a {
			color: transparentize(map-get($theme-colors, dark), .3);
		}
	}

	.close {
		float: right;
	    color: #000;
	    font-size: 1.5rem;
	    line-height: 1em;
	    font-weight: 700;
	    text-decoration: none;
	    opacity: .3;
	    @include transition(all .2s);
	    &:hover {
	    	opacity: .6;
	    }
	}
}


// // Webform
// .webform-client-form {
// 	> div {
// 		@include make-row();
// 		> div:not([class*="col-"]) {
// 			@include make-col-ready();
// 			@include make-col(12);
// 		}
// 	}
// 	.form-actions {
// 		padding-left: 15px;
// 		padding-right: 15px;
// 		input {
// 			& + input {
// 				margin-left: 15px;
// 			}
// 		}
// 	}
// }
// .webform-progressbar {
// 	margin-bottom: 25px;
// }
// .webform-container-inline {
// 	&.form-item {
// 		> label, > .form-select, > .form-text, > .form-textarea {
// 			display: inline-block;
// 			vertical-align: middle;
// 			margin: 0;
// 			width: auto;
// 			max-width: 100%;
// 		}
// 		> label {
// 			margin-right: 10px;
// 		}
// 	}
// 	> .form-item {
// 		display: inline-block !important;
// 		vertical-align: middle;
// 		margin: 0;
// 		width: auto;
// 		max-width: 100%;
// 	}
// }
// .webform-component-time, .webform-component-grid {
// 	.form-type-radio {
// 		margin: 0;
// 	  label {
// 	    padding-left: 0;
// 	  }

// 	  input {
// 	    position: relative;
// 	    margin-top: 0;
// 	  }
// 	}
// }
// .webform-component-time {
// 	.form-radios {
// 		@include media-breakpoint-down(xs) {
// 			display: block;
// 		}
// 	}
// }
// .webform-component-file {
// 	input[type="submit"] {
// 		margin: 15px 0 5px;
// 	}
// }


// //Field
// .field-type-image {
// 	img {
// 		max-width: 100%;
//     	height: auto;
// 	}
// }
