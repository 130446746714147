#slider {
  height: 100%;
  position: relative;

  .swiper-container {
    height: 100%;

    .swiper-slide {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

  }
}

.swiper-button-prev,
.swiper-button-next {
  outline: none;
  background: rgba(0, 0, 0, .4);
  width: 4rem;
  background-size: 20px;
  background-repeat: no-repeat;

}

.swiper-button-prev {
  left: 0;
  background-image: url('../images/prev.png');
  background-position: 4px center;


}
.swiper-button-next {
  right: 0;
  background-image: url('../images/next.png');
  background-position: 14px center;
}
.gallery-thumbs {
  height: 100px;
  box-sizing: border-box;
  padding: 10px 0;

  .swiper-slide {
    width: 100px !important;
    height: 100%;
    opacity: 0.4;

    &-thumb-active {
      opacity: 1;
    }

    .thumbnails {
      min-height: 100px !important;
    }

  }
}
