
// Button
// =============================================================================

.btn {
  display: inline-block;
  border: 3px solid transparent;
  border-radius: 20px;
  padding: 5px 20px;
  box-shadow: none !important;
  font-family: $font-family-sans-serif;
  font-size: 0.94rem;
  font-weight: 400;
  line-height: 1.8em;
  white-space: normal;
  text-decoration: none !important;
  @include transition(all .35s);

  &.btn1 {
    background-color: theme-color("primary");
    border-color: theme-color("primary");
    color: #fff;
    text-decoration: none !important;

    &:after {
      content: '\f105';
      font-family: $font-awesome;
      margin-left: 10px;
      font-weight: 600;
    }

    &:hover {
      background-color: darken(theme-color("primary"), 10%);
      border-color: darken(theme-color("primary"), 10%);
      color: #fff;
      text-decoration: none;
    }

  }
  &.btn2 {
    background-color: #fff;
    border-color: theme-color("primary");
    color: theme-color("primary");
    font-weight: 600;
    text-decoration: none !important;
    &:hover {
      background-color: theme-color("primary");
      border-color: theme-color("primary");
      color: #fff;
    }

  }

}

input[type="submit"] {
  @extend .btn;
  @extend .btn-primary;
  cursor: pointer;
}

.cta {

}
