// Custom


.webform-client-form {

  > div {

    p {
      max-width: 600px;
    }

    .webform-progressbar {
      display: none;
    }

    .form-item {
      width: 100%;

      &:nth-child(n+1) {
        margin-top: 2rem;
      }

      &.webform-component--message {
        width: 100%;
        margin-left: 0;
      }

      input {
        padding: 1rem 1.5rem;
        width: 100%;
      }

      @include media-breakpoint-up(sm) {
        width: calc(50% - 1rem);
        float: left;

        &:nth-child(n+1) {
          margin-top: 0;
        }

        &:nth-child(n+4) {
          margin-top: 2rem;
        }

        &:nth-child(2n) {
          margin-right: 2rem;
        }

      }

    }

  }

  .webform-submit {
    margin-top: 2rem;
    float: right;
    border-radius: 0;
    background: #000;
    color: #fff;
    padding: 1rem 1.5rem;
    border: none;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    transition: .3s ease-out;
  }

  .captcha.form-wrapper {
    margin-top: 2rem;
    clear: both;
    display: inline-block;

    .fieldset-legend,
    .fieldset-description {
      display: none;
    }

  }


}
