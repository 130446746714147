// -------------------------------------------------------------
//   Main layout
//   Defines basic main styles in layout
// -------------------------------------------------------------

html {
  font-size: 62.5%;
}
body {
  background-color: #fff;
  color: #292b2c;
  font-size: 1.6rem;
  line-height: 1.5em;


  main {
    @include media-breakpoint-down(lg) {
      margin-top: 6rem;
    }
  }
}

.container,
.container-no-gutter  {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.iframe-embed {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  padding-top: percentage(9 / 16);
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
.page-user {
  main {
    form {
      @extend .container;
      padding-top: 300px;
    }
  }
}

h1 {
  padding: 2rem;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.messages.error {
  display: none;
}

.cta {
  background: #000;
  color: #fff;
  padding: 1rem 1.5rem;
  border: none;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  transition: .3s ease-out;

  &:hover {
    background: lighter(#000);
    text-decoration: none !important;
  }
}


