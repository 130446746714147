

.status-item {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 1;
  color: #fff;
  background: #000;
  padding: 0 .5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10%;
}

.view-shop {

  .view-content {
    padding: 0 .5rem;
    z-index: -2;

    .produit {
      margin-bottom: .5rem;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;

      a {
        display: block;
        position: relative;
        min-height: 300px;
        padding: 2rem 2rem;
        z-index: 0;
        font-size: 2rem;
        text-decoration: none;
        font-weight: 400;
        color: #000;

        span {
          display: none;
        }

        @include media-breakpoint-up(lg) {
          color: #fff;
          span {
            display: block;
          }

        }

      }

    }

    @include media-breakpoint-up(sm) {
      display: flex;
      flex-flow: row wrap;

      .produit {
        width: calc(50% - .25rem);

        &:nth-child(2n) {
          margin-left: .5rem;
        }

      }

    }

    @include media-breakpoint-up(md) {
      max-width: 1170px;
      width: 100%;
      margin: 0 auto;

      .produit {
        width: calc(33.3333333% - 0.366667rem);
        z-index: 0;

        &:nth-child(2n) {
          margin-left: 0;
        }

        &:nth-child(3n-1) {
          margin: 0 .5rem .5rem;
        }

      }

    }

    @include media-breakpoint-up(lg) {

      .produit {

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          opacity: 0;
          transition: .3s ease-out;
          background: rgba(0, 0, 0, .5);
          z-index: -1;
        }

        a {
          position: relative;
          &::after {
            display: none;
          }

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            text-align: center;
          }

        }

        &:hover {

          &::after {
            opacity: 1;
          }

          a {
            opacity: 1;
            transition-delay: .4s;
          }

        }

        a {
          z-index: 0;
          padding: 6rem 2rem;
          opacity: 0;
          transition: .3s ease-out;

        }

      }

    }

  }

}


// Single article

.node-type-produit {

  article {

    h1 {
      text-align: left !important;
      padding: 1rem 0 0 0;
      font-size: 3.1rem;
      margin-bottom: 1rem;

    }
    .swiper-slide > a {
      display: block;
      min-height: 300px;
      background-color: #fff;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: zoom-in;

      @include media-breakpoint-up(sm) {
        min-height: 450px;
      }

    }

    .wrapper-description {
      padding: 0 1rem;

      .prix {
        font-size: 2rem;
      }

      p {
        margin: 1rem 0;
      }

      .buttons {
        display: block;
        margin: 4rem 0;
      }

      .reference {
        margin: 1.5rem 0;
        display: block;
        font-size: 1.4rem;
      }

      .dimensions p {
        margin: 0;
        font-size: 1.4rem;
      }

    }

    .thumbnails {
      background-position: center;
    }

    @include media-breakpoint-up(md) {
      padding: 0 2rem;

      h1 {
        padding: 0;
        font-size: 3.1rem;
      }

      .wrapper-galerie,
      .wrapper-description {
        float: left;
      }

      .wrapper-description {
        margin-left: 6rem;
        width: calc(40% - 3rem);
      }

      .wrapper-galerie {
        width: calc(60% - 3rem);
        .swiper-slide div {
          min-height: 500px;
        }

      }

    }

    @include media-breakpoint-up(lg) {
      margin-top: 4rem;
    }

  }

}
