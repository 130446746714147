// -------------------------------------------------------------
//   Main footer
//   Footer styles
// -------------------------------------------------------------

#footer {
  height: 4rem;
  line-height: 4rem;
  background: #fff;
  color: #000;

  p {
    margin: 0;
    text-align: center;
    font-size: .8rem;
    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  a {
    color: #000;
    transition: .2s ease-out;
    text-decoration: none;

    &:hover {
      color: rgba(0, 0, 0, .6);
    }
  }
}

.front #footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

// .footer_product {
//   @include media-breakpoint-up(lg) {
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     z-index: 2;
//   }
// }
