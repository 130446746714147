// -------------------------------------------------------------
//   Types
// -------------------------------------------------------------

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
	margin-bottom: (1rem / 2);
	color: inherit;
	line-height: 1.1;
	font-family: inherit;
	font-weight: 500;
}
h1,.h1{
	font-size: 2.2rem;
}
h2,.h2 {
	font-size: 2rem;
	color: #000;
	font-weight: 400;
	margin-bottom: 20px;
}
h3,.h3 {
	font-size: 1.7rem;
	color: theme-color("primary");
	margin-bottom: 10px;
}
h4,.h4 {
	font-size: 1.5rem;
	color: theme-color("primary");
	margin-bottom: 10px;
}
h5,.h5 {
	font-size: 1.25rem;
	color: theme-color("primary");
	margin-bottom: 10px;
}
h6,.h6 {
	font-size: 1rem;
	color: theme-color("primary");
	margin-bottom: 10px;
}

ul {
	li {

	}
}

a {
	color: #000;
	text-decoration: underline;

	&:hover {
		color: lighter(#000, 50%);
	}

}

.evidence {
  margin: 20px 0;
  color: #000;
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0 auto;
  border: 1px solid #000;
  padding: 2rem;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.not-front main {

  h1 {
    text-align: center;
  }

}
