// -------------------------------------------------------------
//   Paragraphs item texte
// -------------------------------------------------------------

.content-texte {
	padding: 30px 0;

  .row {
    margin: 0 !important;
  }

	&.normale {
		padding: 30px 80px;
		@include media-breakpoint-down(lg) {
      padding-left: 30px;
      padding-right: 30px;
    }

    @include media-breakpoint-down(sm) {
     padding-left: 15px;
     padding-right: 15px;
   }

 }
 .row {
  margin-bottom: 70px;
  margin: 0 2rem;

  @include media-breakpoint-up(md) {
    margin: 0 0 70px 0;
  }

  &:last-child {
   margin-bottom: 0;
 }

 .col-md-6 {
  border: 1px solid #000;
  max-width: none;
  padding: 4rem 2rem;

  @include media-breakpoint-up(md) {
    padding: 4rem;
    width: calc(50% - 2rem);
    flex: none;
    &:first-child {
      margin-right: 4rem;

    }

  }

  &:first-child {

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }

  }

}

}
img {
  max-width: 100%;
  height: auto !important;
}

}
