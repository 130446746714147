// -------------------------------------------------------------
//   Main header
//   Header styles
// -------------------------------------------------------------

#header {
  position: relative;
  z-index: 3;
  .logo {
    display: block;
    width: 75px;
    height:30px;
    background: url('../images/logo.svg');
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 2rem;
  }

  @include media-breakpoint-up(lg) {
    background: #fff;

    .logo {
      width: 110px;
      height:45px;
    }
  }

  .wrapper-sup-header {
    max-width: 1170px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 2rem 0;

    @include media-breakpoint-down(md) {
      position: fixed;
      top: 0;
      z-index: 12;
    }

    .fa-facebook-f {
      position: relative;
      margin-right: 2rem;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -1.2rem;
        height: 100%;
        width: 2px;
        background: rgba(0, 0, 0, .5);
      }
    }
  }
}
