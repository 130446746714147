.node-type-webform {
  .node-webform {
    @include media-breakpoint-up(lg) {

      display: flex;
      flex-flow: row wrap;

      > div,
      form {
        width: calc(50% - 2rem);
        margin-left: 2rem;
      }
    }
  }
}
