// -------------------------------------------------------------
//   Homepage
//   Homepage specific styles
// -------------------------------------------------------------

html {
  height: 100%;
}

body.front  {
  height: 100%;

  main {
    height:100vh;
    margin-top: 0;

    @include media-breakpoint-up(md) {
    }
    @include media-breakpoint-up(lg) {
      margin-top: -7rem;
      height: calc(100vh - 12rem);
    }
    .breadcrumb {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 90%;
      h2 {
        font-size: 3.2rem;
        font-weight: 900;
        text-align: center;
        animation: slide-up;
        animation-fill-mode: forwards;
        animation-duration: .9s;
        opacity: 0;
        color: #fff;
      }
      p {
        text-align: center;
        animation: slide-up;
        animation-fill-mode: forwards;
        animation-duration: .9s;
        animation-delay: .6s;
        opacity: 0;

        @include media-breakpoint-up(md) {

        }
      }

      @include media-breakpoint-up(md) {
        max-width: 550px;

        h2 {
          font-size: 6.2rem;
          text-align: center;
        }

      }

      @include media-breakpoint-up(lg) {
        max-width: 750px;

      }
    }
  }

  .content {
    display: none;
  }
}
