
#secondary-nav {
  position: relative;
  display: none;
  margin-right: 2rem;

  @include media-breakpoint-up(lg) {
    display: block;
    line-height: 45px;
  }

  p {
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
    float: left;
    margin-right: 1rem;
  }

}

.trigger-menu {
  width: 40px;
  height: 25px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: tranform .5s ease-in-out;
  -moz-transition: tranform .5s ease-in-out;
  -o-transition: tranform .5s ease-in-out;
  transition: tranform .5s ease-in-out;
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 12;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: theme-color("secondary");
    border-radius: 5px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2) {
      top: 10px;
    }

    &:nth-child(3) {
      top: 20px;
    }

    @include media-breakpoint-up(lg) {
      display: none !important;
    }

  }

  &.open {
    position: fixed;
    top: 20px;
    right: 20px;

    span {
      background: #fff;

      &:nth-child(1) {
        top: 10px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }

      &:nth-child(2) {
        opacity: 0;
        left: -60px;
      }

      &:nth-child(3) {
        top: 10px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }

    }

  }

}

.wrapper-nav {

  @include media-breakpoint-up(lg) {
    overflow: hidden;
    border-top: 1px solid #000;
  }

  #main-nav {
    display: none;
    z-index: 3;

    @include media-breakpoint-up(lg) {
      display: block;

      .menu {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;


        li {
         position: relative;
         padding: 2rem 1rem;

         &:hover {
           &::after {
             content: '';
             position: absolute;
             top: 0;
             left: 0;
             width: 100%;
             height: 3px;
             background: #000;
           }
         }

         &:last-child {
          padding-right: 0;
        }

        a {
          text-decoration: none;
          font-weight: 400;
          color: #000;
          padding: 2rem 1rem;

          &.active {
            &::after {
             content: '';
             position: absolute;
             top: 0;
             left: 0;
             width: 100%;
             height: 3px;
             background: #000;
           }

         }

       }

     }

   }

 }

}

}

@include media-breakpoint-up(lg) {
  .d-xl-none {
    display: none !important;
  }

}

@include media-breakpoint-down(md) {

  body.open-menu {
    #header {
      height: 100vh;

      .logo {
        -webkit-filter: brightness(0) invert(1);
        -moz-filter: brightness(0) invert(1);
        -ms-filter: brightness(0) invert(1);
        -o-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
      }

      #secondary-nav {
        display: block;
        position: fixed;
        top: 2.5rem;
        left: 12rem;
        z-index: 4;
        color: #fff;

        a {
          color: #fff;
        }

      }
      #main-nav {
        display: block;
        background: #000;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .menu {
          display: flex;
          flex-flow: column wrap;
          justify-content:center;
          align-items: center;
          height: 100%;

          li {
            padding: 1rem 0;
            position: relative;

            a {
              font-size: 2.4rem;
              text-decoration: none;
              position: relative;
              color: #fff;

              &:hover {
                text-decoration: none;
              }

              &.active {

                &::after{
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: -2rem;
                  transform: translateY(-50%);
                  width: 5px;
                  height: 5px;
                  background: #fff;
                }

              }

            }

          }

        }

      }

    }

  }

}
